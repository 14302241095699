<template>
  <div class="tnc-background">
    <div class="tnc-container">
      <h1 class="tnc-header">
        <img src="@/assets/inava-icon.png" alt="inava-logo">
        <span>Terms of Use</span>
      </h1>
      <p>SG Analytics owns and operates the iNava platform, and provides the content and services on the Platform. These Terms of Use apply to all users of the Platform. By signing up for iNava and accessing the platform, you ("You" or "Customer") indicate your acceptance of these Terms of Use.</p>
      <ol>
        <li>
          <strong>iNava Service Terms</strong>
          <p>Subject to the terms and conditions of this Agreement, SG Analytics grants to Customer: (i) a revocable, non-exclusive, non-transferable license during the Term to access and use the iNava Platform; and (ii) a revocable, perpetual, non-exclusive, non-transferrable, royalty-free, fully paid-up worldwide license to use, reproduce, access and store any modifications, compilations, derivative works and results from processing (including analyses, reports and visual representations) created or developed by SG Analytics in connection with the services or content on the iNava Platform, solely for Customer's internal business purposes.</p>
          <p>Customer acknowledges that the Services may be dependent on provision of information or access to Customer systems. SG Analytics will use commercially reasonable efforts to: (i) provide to Customer basic customer support; and (ii) make the Website available 24 hours a day, 7 days a week, with minimal downtime; except for: (1) planned downtime for maintenance and scheduled upgrades (which may alter the functionality of the Website); and (2) unavailability caused by circumstances beyond  reasonable control, including acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labour problems, Internet service provider failures or delays, or the unavailability of any third-party provided services. SG Analytics may alter the functionality and user interfaces of the Website from time to time in its sole discretion as part of providing the Services and improving the user experience for customers.</p>
        </li>
        <li>
          <strong>Reservation of Rights.</strong>
          <p>SG Analytics reserves all rights in the Platform, the services and content provided by iNava hereunder and not specifically granted to Customer. All right, title and interest in the iNava Platform, as well as any update, modification, adaptation, translation, customization or derivative work thereof, and all intellectual property rights therein will remain with SG Analytics. </p>
          <p>Customer expressly reserves all rights in the Customer Data, subject to the license that Customer grants iNava in accordance with the provisions of this Agreement. Customer does not acquire any intellectual property rights in the Platform, the Services, or any elements of any of the foregoing.</p>
          <p>iNava shall have a royalty-free, worldwide, transferable, sub-licensable, irrevocable, perpetual, unrestricted license to use and/or incorporate into its products, services and business any suggestions, enhancement requests, recommendations or other feedback provided by Customer relating to the operation of the Services.</p>
        </li>
        <li>
          <strong>Privacy</strong>
          <p>Customer agrees (on Customer’s behalf and on behalf of each Permitted User) to iNava’s access, use, collection, storage and disclosure of Customer’s and each Permitted User’s Personal Information for the purposes authorized under this Agreement. Customer understands that Personal Information, including the Personal Information of Permitted Users, will be treated in accordance with iNava’s privacy policy located at <router-link :to="{name: 'privacyPolicy'}">https://www.inava.io/privacy-policy/</router-link> (the Privacy Policy).</p>
          <p>Customer expressly reserves all rights in the Customer Data, subject to the license that Customer grants iNava in accordance with the provisions of this Agreement. Customer does not acquire any intellectual property rights in the Platform, the Services, or any elements of any of the foregoing.</p>
          <p>iNava shall have a royalty-free, worldwide, transferable, sub-licensable, irrevocable, perpetual, unrestricted license to use and/or incorporate into its products, services and business any suggestions, enhancement requests, recommendations or other feedback provided by Customer relating to the operation of the Services.</p>
        </li>
        <li>
          <strong>Customer User Account</strong>
          <p>Upon Customer’s request and except as otherwise specified in the Order Form, SG Analytics will issue one administrator account to Customer that provides Customer with the capability to create end user accounts for employees that the Customer wishes to have access to and use of the iNava Platform. Customer will ensure that Permitted Users only use the iNava Platform through the Customer User Account. Customer will not allow any Permitted User to share the Customer User Account with any other person. Customer is responsible for identifying and authenticating all Permitted Users and for Permitted Users’ compliance with this Agreement. Customer will promptly notify SG Analytics of any actual or suspected unauthorized use of the iNava Platform. iNava reserves the right to suspend, deactivate, or replace the Customer User Account if it determines that the Customer User Account may have been used for an unauthorized purpose.</p>
        </li>
        <li>
          <strong>Support and Security</strong>
          <p>SG Analytics uses industry-standard security measures with respect to access, passwords, firewalls and physical protections to ensure that the customer data on the iNava platform is protected from unauthorized access. However, SG Analytics makes no warranty that the contents of the Platform are free from infection by viruses or anything else which has contaminating or destructive properties and shall have no liability in respect thereof. You understand that SG Analytics may monitor activity on the services and will use reasonable efforts to provide technical support for the iNava platform.</p>
        </li>
        <li>
          <strong>Availability</strong>
          <p>SG Analytics has taken every care in the preparation of the iNava Platform. However, certain technical matters may be beyond SG Analytics’s control, and SG Analytics therefore cannot always guarantee an uninterrupted or error-free access to the iNava Platform.</p>
        </li>
        <li>
          <strong>Fees and Payment.</strong>
          <p>The Customer will pay SG Analytics the set-up fee (if any) for the iNava platform on or before the Effective Date and will pay the subscription fee in the amounts and frequency indicated in the License Authorization Form. SG Analytics may send the Customer the invoices for amounts that have become due and payable under this Agreement. The Customer agrees to pay all undisputed invoiced amounts within 30 calendar days of the invoice receipt. SG Analytics may suspend Customer's access to the Services until all due amounts are paid. Any late payment will be increased by the costs of collection (including reasonable legal fees), and will incur interest at the rate of one and a half percent (1.5%) compounded monthly (19.56% annually), or the maximum legal rate (if less) until fully paid.</p>
          <p>Fees and charges quoted in this Agreement do not include, and Customer shall pay, all sales, use, gross receipts, value-added, GST/HST, personal property or other taxes, and all applicable duties, tariffs, assessments, export and import fees or similar charges.</p>
          <p>SG Analytics reserves the right to change the Fees and institute new charges at the beginning of each Renewal Term.</p>
        </li>
        <li>
          <strong>Confidential Information.</strong>
          <p>Each party agrees to keep confidential any information, including Customer Data, communicated by the other party in connection with this Agreement that is (i) clearly marked confidential if provided in written form, (ii) if provided in oral form, preceded or followed within 15 days by a statement that such information is confidential, or (iii) information which, given its nature or the circumstances of its disclosure, should be reasonably understood as confidential (the "Confidential Information"). This obligation of confidence shall not apply to any information that: (1) is generally publicly available at the time of its communication; (2) is independently developed or obtained by the receiving party without reference to the Confidential Information; (3) becomes generally publicly available through no fault of the receiving party subsequent to the disclosing party's communication to the receiving party; (4) is in the receiving party's possession free of any obligation of confidence at the time of the disclosing party's communication to the receiving party; or (5) is communicated to the receiving party by a third party free of any obligation of confidence. Additionally, the receiving party may disclose such information to the extent required by legal process, provided that the receiving party has notified the disclosing party in writing prior to such required disclosure and, to the extent reasonably possible, has given the disclosing party an opportunity to contest such required disclosure.</p>
        </li>
        <li>
          <strong>Warranty and Disclaimer</strong>
          <p>SG Analytics warrants that the iNava Platform will perform substantially in conformance with industry acceptable performance standards, and that any Customer Data shall be processed only in connection with fulfilling its obligations pursuant to this Agreement. Services and content provided on the iNava Platform shall comply with all applicable statutes, statutory instruments, regulations, binding codes, rules and orders.</p>
          <p>The iNava Platform and all content, materials, information and services, provided on the Platform, are provided on an "as is" and "as available" basis. SG Analytics endeavours to provide information that is accurate. However, SG Analytics expressly disclaims any and all warranties, including but not limited to any warranties as to the accuracy and completeness of any information provided.</p>
        </li>
        <li>
          <strong>Limitation of Liabilities</strong>
          <p>Subject to the terms and conditions of this Agreement, SG Analytics will, at its own expense, defend Customer in any action, suit or proceeding by a third party alleging that the Services infringe or misappropriate any patent, trademark, trade secret, copyright or any other intellectual property rights of such third party (an "IP Claim") and shall indemnify and hold Customer harmless from and against any settlement amounts agreed in writing by SG Analytics and/or any losses, damages, expenses or costs (including but not limited to reasonable attorneys' fees) awarded to such third party against Customer by a court or tribunal of competent jurisdiction in such IP Claim. As conditions for such defence and indemnification by SG Analytics, (i) Customer shall promptly notify SG Analytics in writing upon becoming aware of any pending IP Claim; (ii) Customer shall give SG Analytics the sole control of the defence and settlement of such IP Claims; (iii) Customer shall cooperate fully with SG Analytics in the defence or settlement of such IP Claims; and (iv) Customer shall not settle any IP Claims without SG Analytics’s written consent, or compromise the defence of any such IP Claims or make any admissions in respect thereto. These obligations do not extend to: (i) the unauthorized combination or use by Customer of the Services with third party products, provided that the alleged infringement would not have occurred but for such combination, (ii) any unauthorized modification or alteration of the Services by Customer, provided that the alleged infringement would not have occurred but for such modification or alteration, (iii) use of the Services after written notice of the claimed infringement has been received by Customer, or (iv) actions by Customer or its agents in breach of this Agreement.</p>
          <p>Customer agrees to indemnify and hold harmless SG Analytics against any and all liability (including damages, recoveries, deficiencies, interest, penalties and reasonable attorney's fees) to third parties relating to: (i) Customer Data, (ii) Customer's breach of any of Customer's obligations, representations or warranties under this Agreement; or (iii) Customer's use of the Services, including in combination with any third party software, application or service, in breach of the terms of this Agreement. Personal or property damage arising out of or in any way connected to this agreement, regardless of cause of action or the theory of liability, whether in contract, tort or otherwise. In no event shall SG Analytics be liable for procurement or costs of substitute products or services.</p>
          <p>Except for liability with respect to the indemnification obligations under section 8 or for a breach of confidentiality obligations set forth in section 9, in no event will the total aggregate liability of either party in connection with or under this agreement exceed the total amount of fees received by SG Analytics under this agreement from the Customer. For greater certainty, the existence of one or more claims under this agreement will not increase this maximum liability amount. Except for damages relating to a breach of section 8 (Confidential Agreement), in no event shall either party be liable to the other for any (i) special, indirect, incidental or consequential damages, (ii) lost savings, profit, data, use, or goodwill, (iii) business interruption, even if notified in advance of such possibility, or (iv) personal or property damage arising out of or in any way connected to this agreement, regardless of cause of action or the theory of liability, whether in contract, tort or otherwise. In no event shall SG Analytics be liable for procurement or costs of substitute products or services.</p>
        </li>
        <li>
          <strong>Term and Termination.</strong>
          <p>This Agreement will commence on the date of acceptance by the customer and will continue for the license term set forth on the License Authorization form unless and until terminated by one party giving written notice of at least 90 days to the other Party at any time or if: (i) the other Party breaches any material provision hereof, and fails within fifteen (15) days after receipt of notice of such breach to correct such material breach or to commence corrective action reasonably acceptable to the aggrieved Party; or (ii) the other party becomes insolvent, makes an assignment for the benefit of its creditors, a receiver is appointed, or a petition in bankruptcy is filed with respect to the Party and is not dismissed within thirty (30) days. The following Sections will survive expiration or termination of this Agreement for any reason: Section 2 (Reservation of Rights), Section 4 (Customer User Accounts), Section 9 (Warranty and Disclaimer), Section 10 (Limitations of Liability), Section 8 (Confidential Information), and Section 15 (General Provisions).</p>
          <p>On termination, the customer will no longer have rights to access or use the iNava Platform. Within 90 days following termination, SG Analytics will, at Customer’s option, return to Customer or delete or otherwise render inaccessible any Customer Data that remains in the hardware or systems used by the iNava Platform. If the Agreement is terminated prior to completion of the license term, the customer shall be entitled to a prorated refund reflecting the unused portion of any prepaid fees.</p>
        </li>
        <li>
          <strong>Citation Policy</strong>
          <p>The use of graphics and content from the iNava Platform can be used for internal information and communications purposes and does not require permission from does not require permission from SG Analytics, but it must provide the following attribution, "Source: iNava/ SG Analytics."</p>
          <p>External reproduction of the iNava content in any form is forbidden without SG Analytics's express written permission.</p>
        </li>
        <li>
          <strong>Written Permission</strong>
          <p>If you wish to quote iNava in an ad, press release, or other promotional material, contact SG Analytics. Please provide a sample documenting the intended use of the information via email to teaminava@sganalytics.com . This will enable SG Analytics to ensure accuracy, currency, and proper context.</p>
        </li>
        <li>
          <strong>External Links</strong>
          <p>Some pages on the iNava Platform may provide links to other sites. SG Analytics does not warrant, endorse, or guarantee the products, services, or information described or offered, and is not responsible for the availability or content of these sites. SG Analytics is not responsible for the availability of them and will not be liable in any way for any loss or damage which you may suffer by your use or inability to use those websites. Further, users cannot assume that the external sites will abide by the same Privacy Policy to which iNava adheres. Users should therefore review the external site's privacy policy to learn how information provided to the external site may be used and/or shared.</p>
        </li>
        <li>
          <strong>General Provisions</strong>
          <p>Customer may not assign this Agreement to any third party without SG Analytics’s prior written consent. The terms of this Agreement shall be binding upon and inure to the benefit of the Parties' successors and permitted assignees. This Agreement and any action related thereto shall be governed by and construed in accordance with the laws of the State of New York.</p>
          <p>SG Analytics may refer to Customer in iNava's list of customers and may use Customer's name and logo for marketing-related purposes, provided, in either case, that Customer has given advance written approval.</p>
          <p>Neither Party shall be liable for delays caused by events beyond its reasonable control. Any provision hereof found by a tribunal of competent jurisdiction to be illegal or unenforceable shall be automatically conformed to the minimum requirements of law and all other provisions shall remain in full force and effect.</p>
          <p>Customer agrees that the information provided to SG Analytics when they use the iNava Platform will be true and complete, and that it will be kept true and complete. By submitting personal information via any of the online forms on the site, you consent to being contacted (either by phone or email) by an iNava/ SG Analytics representative.</p>
          <p>SG Analytics reserves the right at any time, without liability for doing so, to modify or discontinue, temporarily or permanently, any of the content, functions or services we offer on the iNava platform (or any part of them) with or without notice. This includes removing information transmitted by you to the iNava Platform.</p>
          <p>All rights not expressly granted by SG Analytics for the usage of iNava Platform to the Customer under this Agreement are reserved.</p>
          <p>This Agreement, together with the iNava Privacy Policy, constitutes the entire agreement between the Parties with respect to the subject matter hereof. This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which shall be taken together and deemed to be one instrument.</p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions'

}
</script>

<style lang="scss" scoped>
.tnc-background {
  height: 100vh;
  width: 100%;
  background: #535eeb url('../../assets/login-container-sm.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
    overflow: auto;
  .tnc-container {
    .tnc-header {
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      margin: revert;
      span {
        display: block;
      }
      img {
        width: 50px;
      }
      p {
        margin-top: -5px;
      }
    }
    text-align: justify;
    background: white;
    padding: 30px 50px;
    margin: 50px;
    border-radius: 10px;
    ol {
      margin: revert;
      padding: revert;
      margin-right: 35px;
      li {
        &::marker {
          font-weight: bold;
        }
        p {
          margin: revert;
        }
      }
    }
  }
}
</style>
